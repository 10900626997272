// HEADER
//////////////////////////////
.header-slider {
    display: none;

    @include breakpoint(large) {
        display: block;
    }
}
body.cmsBackend {
    #header {
    display: none!important;
    }
}

#header {
    position: relative;
        height: 27rem;
    width: 100%;
    background: url(../images/layout/tiny-headerBg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    color: $light;
     margin-top: 2.9rem;
    font-weight: 400;
        padding: 5rem 0px;

    &.lazyLoaded {
        background-size: cover
    }

  
    @include breakpoint(small) {
    height: 25.5rem;
     }
    
    @include breakpoint(large) {
        background: none;
        height: auto;
            margin-top: 5.9rem;
        padding:0;
    }

    body:not(.index) & {
        height:12rem;

        @include breakpoint(tiny) {
            background-position: 0;
        }
        @include breakpoint(medium) {
            height: auto;
         }
        

        @include breakpoint(large) {
        }
        .claim {
            .liste,
            .call {display: none;}   
        }

        .tns-inner {
            overflow: hidden !important;

            @include breakpoint(large) {
                height: 235px;
            }

            @include breakpoint(giant) {
                height: 345px;
            }

            img {
                @include breakpoint(giant) {
                    top: -170px;
                    position: relative;
                }
            }

        }


        .claim {
            display: none;

            @include breakpoint(large) {
                display: block;
                margin: 2rem 0 0rem;

                .btn {
                    display: none;
                }
            }
        }
    }

    .claim {
        height: 10rem;
        width: 100%;
        text-align: center;
        position: absolute;
        z-index: 1 !important;
        display: block;

        @include breakpoint(large) {
            margin: 0;
            width: 80%;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translatey(-50%);
        }
        @include breakpoint(giant) {
                  top:44%;
              }
        

        @include breakpoint(large) {
            padding: 0 6rem;
            width: 90%;
        }


        @include breakpoint(giant) {
            padding: 0 10rem;
            width: 100%;

        }

        @include breakpoint(full) {
            padding: 0 22rem;
        }

        .big {
            margin-bottom: 1rem;
            font-size: rem(30.7px);
            line-height: rem(47px);
            font-weight: 600;
            text-transform: uppercase;

            @include breakpoint(tiny) {
                margin-bottom: 0;
            }

            @include breakpoint(giant) {
                font-size: rem(52px);
                padding-bottom: rem(20px);
            }


        }
        .liste {
            a {
                color:$light;
                    padding-right: 0.5rem;
                    border-right: .05556rem solid $light;
                text-decoration: none;
                    margin-right: 0.5rem;
                    @include breakpoint(medium) {
                     padding-right: 3%;
                      margin-right: 1.4rem;
                     }
                    
                &:last-child {
                    border:none;
                }
                &:hover {
                   
                        text-decoration: underline;
                }
            }
            margin-bottom: 2rem;
            @include breakpoint(huge) {
            margin-bottom: 3rem;
             }
            
        }
        .call {
            background:$light;
            color:$dark;
            padding:20px;
            display: block;
            font-weight: 600;
            @include breakpoint(tiny) {
            display: inline-block;
             }
            
            a {
                display: block;
                @include breakpoint(tiny) {
                display: inherit;
                 }
                
            }
        }

        .small {
            display: none;

            @include breakpoint(tiny) {
                display: block;
                font-weight: 600;
                  font-size: 23px;
                margin-bottom: 1rem;
                line-height: rem(30px);
            }

            @include breakpoint(medium) {
                line-height: rem(36px);
                font-size: rem(22px);
            }

            @include breakpoint(giant) {
                font-size: rem(44px);
                line-height: 2.4rem;
            
                padding-bottom: rem(4px);
            }


        }

        .btn {
            margin-top: 1rem;

            @include breakpoint(small) {
                margin-right: 0.5rem;
            }

            @include breakpoint(medium) {
                margin-bottom: 0rem;
            }

            @include breakpoint(giant) {
                margin-top: 2rem;
            }

        }
    }
}

// MAIN CONTENT
//////////////////////////////

main {
    display: block;

    p,
    ol,
    ul,
    dl,
    table {
        @extend .marginBottom;
    }

    ul:not(.unstyledList),
    dl:not(.unstyledList) {
        @extend .styledList;
    }

    .googleMaps {
        @extend .videoContainer;

        @include breakpoint(medium) {
            height: rem(400px);
        }
    }

    .serviceTeaser {
        color: $light;
        background: url(../images/layout/tiny-teamBg.jpg) no-repeat;
        text-align: left;
        background-size: cover;
        padding: 4rem 0;

        @include breakpoint(small) {
            background: url(../images/layout/large-teamBg.jpg) no-repeat;
            background-size: cover;
        }

        @include breakpoint(medium) {
            padding: 6rem 0;
        }

        @include breakpoint(large) {
            padding: 8rem 0;
        }

        @include breakpoint(huge) {
            background: url(../images/layout/teamBg.jpg) no-repeat;
            background-size: cover;
        }

        .big {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 27px;
            line-height: 35px;
            margin-bottom: 1rem;

            @include breakpoint(medium) {
                font-size: 37px;
            }

            @include breakpoint(giant) {
                font-size: 46px;
                line-height: 50px;

            }


        }

        .small {
            font-size: 21px;
            font-weight: 700;
            line-height: 30px;
            text-transform: uppercase;
            @include breakpoint(medium) {
                font-size: 28px;
            }

            @include breakpoint(giant) {
                font-size: 36px;

            }

        }
        .call {
            background:$light;
             display: inline-block;
            color:$dark;
            font-weight: 400;
            padding: 20px;
            margin-bottom: 0;
        }

    }
    .siegel {
        margin-bottom: 1rem;
    }
}

#privacyPolicy {
    a {
        display: inline-block;
        word-break: break-all;
    }
}

// FOOTER
//////////////////////////////

#footer {
    background: $primary;
    color:$light;
    border-top:1px solid $dark;
    @include breakpoint(medium) {
        border:none;
     }
    
    .col {
            text-align: center;
    }
      .navi {
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
            margin-bottom: 0;
        }

        li {
            display: block;
            text-transform: uppercase;
            border-bottom: 1px solid $light;
            letter-spacing: 0.1rem;
            @include breakpoint(medium) {
                border: none;
                display: inline-block;
            }

            a {
                display: block;
                padding: 1rem;
                text-decoration: none;
                color: $light;
                font-weight: 400;
                    font-size: 12.6px;
                    @include breakpoint(large) {
                    font-size: 17px;
                     }
                    
              
                
                &:hover,
                &.active {
                    background: $light;
                    color: $dark;
                }
            }
        }
    }
   
    .col {
      padding:0;
        @include breakpoint(medium) {
        display: flex;
        justify-content: space-between;
            padding-left: 1.05882rem;
       padding-right: 1.05882rem;
         }
    }
}

.counters {
    background: $secondary;
    color: $dark;
    padding: rem(50px) 0;
    @include breakpoint(medium) {
        padding: rem(100px) 0;
     }


    .mitarbeiter,
    .baustellen,
    .haus,
    .weiterempfehlung {
        text-align: center;
        margin: 0 0 rem(50px);

        @include breakpoint(giant) {
            margin: 0;
        }


        .bold {
            font-size: rem(35px);
            font-weight: 600;
            margin: rem(20px) 0;
        }

        .highlighter {
            font-size: rem(20px);
            margin-bottom: 0;

            @include breakpoint(tiny) {
                font-size: rem(18px);
            }

            @include breakpoint(small) {
                font-size: rem(20px);
            }


        }

        &:before {
            content: "";
              height: 100px;
            display: inline-block;
        }
    }

    .mitarbeiter {
        @include breakpoint(large) {
            margin: 0 !important;
        }

        &:before {
            background: url(/images/layout/icons.png) no-repeat 0 20px;
            width: rem(116px);
        }
    }

    .baustellen {
        @include breakpoint(large) {
            margin: 0 !important;
        }

        &:before {
            background: url(/images/layout/icons.png) no-repeat  0 -89px;
            width: rem(112px);
        }
    }

    .haus {
        @include breakpoint(tiny) {
            margin: 0;
        }

        &:before {
            background: url(/images/layout/icons.png) no-repeat 0 -189px;
            width: rem(117px);
        }
    }

    .weiterempfehlung {
        @include breakpoint(tiny) {
            margin: 0;
        }

        &:before {
            background: url(/images/layout/icons.png) no-repeat 0 -280px;
            width: rem(118px);
        }
    }
}

.referenzenSection {
    padding-bottom: rem(50px) ;
        @include breakpoint(medium) {
            padding-bottom: rem(100px);
         }

    .btn {
        @include breakpoint(giant) {
            margin-top: 3rem;
        }
    }

    a:not(.btn) {
        position: relative;
        margin-bottom: 1rem;
        display: block;
        transition: all .5s;
        overflow: hidden;

        &:hover {
            background: $primary;
            
            span {
              
                

                @include breakpoint(large) {
                    font-size: rem(30px);
                       bottom: 6.6rem;
                }
    
                &:before {
                    @include breakpoint(medium) {
                         width: rem(80px);
                    top: -7rem;
                    height: 110px;
                    width:110px;
                    
                        top: -7rem;
                    }
                }
            }

            img {
                transform: scale(1.1);
                opacity: 0.3;
            }
        }

        img {
            opacity: 1;
            transition: all .5s;
            margin-bottom: 0;
        }

        span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            color: $light;
            font-weight: 600;
            width: rem(290px);
            text-align: center;
            transition: all .5s;
                font-size: 16px;
                    bottom: 1.4rem;
                    
                    
                    @include breakpoint(tiny) {
                        bottom: 3.4rem;
                     }
                    @include breakpoint(small) {
                    bottom:1.1rem;
                     }
                    

                    @include breakpoint(medium) {
                     bottom: 1.7rem;
                      font-size: rem(20px);
                     }

                    

            @include breakpoint(large) {
                bottom: 4rem;
                width: 100%;
            }

            @include breakpoint(giant) {
                bottom: 6rem;
            }

            &:before {
                content: '';
                position: absolute;
                transition: all .5s;
                transition-delay: .3s;
                transform: translateX(-50%);
                background: url('/images/layout/teaser/rohbau-icon.png') no-repeat 0 0;
                display: block;
                background-size: 100% auto !important;
               background-size:contain;
                height:80px;
                width:80px;
                top: -5.3rem;
                left: 50%;
            }

            &.kellerbau:before {
                background: url('/images/layout/teaser/kellerbau-icon.png') no-repeat 0 0;
                background-size: 100% auto !important;
            }

            &.altbau:before {
                background: url('/images/layout/teaser/altbau-icon.png') no-repeat 0 0;
                background-size: 100% auto !important;
            }

            &.mauer:before {
                background: url('/images/layout/teaser/mauer-icon.png') no-repeat 0 0;
                background-size: 100% auto !important;
            }
        }
    }
}

.partner-slider {
    border-top: 1px solid $primary;
    padding:2rem 0;
}
body.internetExplorer,
body.edge  {
    overflow-x: hidden;
}