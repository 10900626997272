.marginBottom {
	margin-bottom: rem($baseLineHeight);
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $dark;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($baseFontSize);
	line-height: rem($baseLineHeight);
	padding: 0.8rem 1rem;
	text-align: center;
	text-decoration: none;
	font-family: $mainFont;

	&:hover,
	&:active,
	&:focus {
		background: lighten($dark, 15%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {

	&:not(.videoTag)
	{
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
	
		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
		}

	}


	video {
		width:100%;
		height:auto;
	}
}

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea
{
	position: fixed;
	bottom:20px;
	left:20px;
	z-index:1000;

	> :not(:last-child)
	{
		margin-bottom:$baseGap;
	}

	.notification
	{
		position: relative;
		transition:300ms all ease-in-out;
		overflow: hidden;
		padding: 1em;
		background: $alert;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width:290px;
		max-width:calc(100vw - 40px);
		transform-origin: left center;

		&, a
		{
			color: $light;
		}

		.close
		{
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			color:#fff;
			font-size:1.2rem;
		}

		&.hide
		{
			opacity:0;
			visibility: hidden;
			transform:scale(0);
			max-height:0;
			height:0;
			padding-top:0;
			padding-bottom:0;
		}
	}
}

// Version: 2.9.1

.tns-outer {
		button {
			    margin-right: 1rem;
	}
  padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
  [hidden] { display: none !important; }
  [aria-controls], [data-action] { cursor: pointer; }
  [data-action] {
  	display: none;
  }
}
.tns-nav {
    bottom: 0;
    position: absolute;
    text-align: center;
    left: 50%;
    margin-bottom: -3rem;
    -webkit-transform: translatex(-50%);
    transform: translatex(-50%);
    button {
    	    border: none;
    width: 0.5rem;
    height: 0.5rem;
    background: $primary;
    margin: 0.5rem;
    }
  }

.tns-controls {
	display: none;
	
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;
    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
  &.tns-no-subpixel {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > .tns-item {
      float: left;
    }
  }
  &.tns-carousel {
    &.tns-no-subpixel {
      > .tns-item {
        margin-right: -100%;
      }
    }
  }
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; // make sure slider container is visible
  // overflow: hidden;
  > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }
  > .tns-slide-active {
    position: relative;
    left: auto !important;
  }
  > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }
}
.tns-autowidth { display: inline-block; }
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
  &.tns-complete { opacity: 1; }
}
.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.tns-ovh { overflow: hidden; }
.tns-visually-hidden { position: absolute; left: -10000em; }
.tns-transparent { opacity: 0; visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}


// *** Fix a viewport issue in initialization
.tns-vpfix {
  white-space: nowrap;
  > div, > li {
    display: inline-block;
  }
}

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;

.tns-t {
  &-subp2 {
    margin: 0 auto;
    width: $width;
    position: relative;
    height: $height;
    overflow: hidden;
  }
  &-ct {
    width: (100% * $count / $perpage);
    width: -webkit-calc(100% * #{$count} / #{$perpage});
    width: -moz-calc(100% * #{$count} / #{$perpage});
    width: calc(100% * #{$count} / #{$perpage});
    position: absolute;
    right: 0;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > div {
      width: (100% / $count);
      width: -webkit-calc(100% / #{$count});
      width: -moz-calc(100% / #{$count});
      width: calc(100% / #{$count});
      height: $height;
      float: left;
    }
  }
}

.center {
	text-align: center;
}

.fullPadding {
	padding: rem(50px) 0;
	@include breakpoint(medium) {
		padding: rem(100px) 0;
	 }
}
