// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $primary;
}
%buttonActive {
	// Link Active
	color: $primary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	.branding {
		img {

			margin: 1rem 0;
			@include breakpoint(large) {
				margin:0;
			 }
			
		}
	}
	.col {padding:0;
		@include breakpoint(large) {
		    padding-left: 1.05882rem;
    	padding-right: 1.05882rem;
		 }
		
	}
	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;
		.col {
			    padding-left: 1.05882rem;
    padding-right: 1.05882rem;
		}
		#navigation {
		    box-shadow: 0 0.44444rem 0.55556rem rgba(0,0,0,0.2);
		        padding: 20px 0px;
		
		.call {
			background:$primary;
			width: 84%;
			float: right;
			color:$light;
			padding:16.2px 10px;
		.icon {
			margin-right:0.5rem;
		}
		@include breakpoint(giant) {;
		        padding: 16.2px 30px;
		 }
		 @include breakpoint(huge) {
		         width: 100%;
		          padding: 16.2px 19px;
		  }
		 
		

	
	}
		nav {
			ul {
				&.navi {
					li {
						text-transform: uppercase;
						font-weight: 700;
						letter-spacing:0.6px;
						a {
						    padding: 1em 0.75882rem;
						    @include breakpoint(giant) {
						        padding: 1em 1.05882rem;
						     }
						    

						}
						&.hasSub {
							.sub {
							}
						}
						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}
